import React from "react"
import isMobile from "is-mobile"
import useSiteMetadata from "../SiteMetaData"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Link, animateScroll as scroll } from 'react-scroll'
import { store } from "react-notifications-component"
import FileSaver from 'file-saver'
import { download } from "../Download"

const Front = () => {
  const { link } = useSiteMetadata()
  //const image = isMobile() ? "/images/front_small.png" : "/images/front_small.png"
  const image = "/images/half-laptop.png"

  function downloadFunc() {
    trackCustomEvent({
      category: "Get Extension",
      action: "Click",
      label: "Get Extension Button Home"
    })
    if (isMobile()) {
      store.addNotification({
        title: '',
        message: 'Download on your Computer',
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "faeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      })
    }
    else {
      store.addNotification({
        title: '',
        message: 'Your Download will Start Shortly',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "faeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      })
      FileSaver.saveAs(link, "beatshine.zip")
    }
    return true
  }

  return (
    <div className="is-fullwidth">
      <div className="columns reverse-columns is-vcentered is-mobile is-multiline">
        <div className="column is-6-fullhd is-5-widescreen is-half-tablet is-12-mobile">
          <div className="section-front section-top-pad-less">
            <div className={'title ' + `${isMobile() ? 'has-text-centered': ''}`}>
              <h1 id="wide-large-text" className="title is-padded-bottom is-size-2-desktop is-size-1-widescreen is-size-3-tablet is-size-4-mobile">
                The Fastest way to Increase your Fanbase on Beatstars
              </h1>
              <div className="subtitle is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-normal" style={{ }}>
                Most powerful tool out here to grow your beatstars account
              </div>
              <div className="subtitle has-text-weight-bold is-size-5-desktop is-size-6-tablet is-size-6-mobile">
                FREE 2-DAY TRIAL | ~4K PLAYS FREE
              </div>
              <Link
                rel="noopener noreferrer"
                to='how-to-section'
                offset={-100}
                duration={1000}
                smooth={true}
                title='Extension Button'
                onClick={() => download(link)}
              >
                <div className="button is-uppercase is-wrapped is-danger is-size-4-desktop is-size-5-tablet is-size-6-mobile" style={{whiteSpace: 'break-spaces' }}>
                  Get the chrome extension
                </div>
              </Link>
              <div className="is-uppercase is-size-4-desktop is-size-5-tablet is-size-6-mobile" style={{ padding: '16px', fontSize: '80%' }}>
                No Credit Card Required
              </div>
            </div>
          </div>
        </div>
        <div className="column is-12-mobile" style={{textAlign: 'right', paddingTop: '2em'}}>
          <img
            style={{
              maxHeight: '600px'
            }}
            src={image}
            alt={'The Fastest way to Increase your Fanbase on Beatstars'}
          />
        </div>
      </div>
    </div>
  )
}

export default Front
