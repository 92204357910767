import React from "react"

const HowToUse = () => (
  <div className="section">
    <div className="container has-text-centered">

      <div className="columns">

        <div className="column">
          <div className="title is-padded-bottom has-text-white is-size-1-widescreen is-size-2-desktop is-size-3-tablet is-size-4-mobile" id="how-to-use-section">
            How to Use
          </div>
          <div className="iframe-container">
            <iframe
              width="560px"
              height="315px"
              title="How to Use | Beatshine"
              src="https://youtube.com/embed/4EBPWEh6DsY"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HowToUse