import React from "react"
import useSiteMetadata from "../SiteMetaData"

const PricingItemsRowOne = [
  {
    name: "Monthly",
    access: "Per month access",
    price: "15",
    months: 1,
    target: "25K+",
    plays: "60K"
  },
  {
    name: "Yearly",
    access: "12 month access",
    price: "99",
    months: 12,
    save: 45,
    target: "all",
    plays: "720K"
  }
]

const PricingItemsRowTwo = [
  {
    name: "Gold",
    accounts: "three beatstars",
    price: "49",
    target: "150K",
    plays: "180k"
  },
  {
    name: "diamond",
    accounts: "ten beatstars",
    price: "99",
    target: "500k",
    plays: "600k"
  }
]

const Pricing = () => {
  const { paymentPortalLink } = useSiteMetadata()
  return (
    <div className="section">
         <div
      className="container"
      id="pricing-section"
      onClick={()=>{
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq('trackCustom', 'Visit Payment Portal');
          }
        }
      }}
    >
      <div
        className="title has-text-centered is-padded-bottom is-size-1-widescreen is-size-2-desktop is-size-3-tablet is-size-4-mobile">
        PRICING
      </div>
      <div className="columns is-mobile is-centered is-variable is-8">
        {PricingItemsRowOne.map((item, index) => (
          <div className="column is-narrow is-4-desktop is-3-widescreen is-4-tablet is-5-mobile">
            <a href={paymentPortalLink} target="_blank">
              <div className="card">
                <div 
                  className="card-header is-block has-background-danger"
                  style={{
                    minHeight: '116px'
                  }}
                >
                  <div className="has-text-centered">
                    <div
                      className="is-uppercase is-padded is-padded-bottom is-size-2-desktop is-size-3-tablet is-size-4-mobile">
                      {item.name}
                      <div className="is-uppercase is-size-5-desktop is-size-6-tablet is-size-6-mobile">
                        <i>
                          {item.save ? ( 'Save ' + item.save + ' %') : ''}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div className="has-text-black is-uppercase has-text-centered">
                    <label className="has-text-danger">{item.access}</label>
                    <br/>
                    {item.months===1 ? 'charged monthly' : 'one time payments'}
                    <br /><br />
                    <div className="title has-text-black is-size-2-tablet is-size-2-mobile">
                      ${item.price} <span className="is-size-4-tablet is-lowercase is-size-5-mobile">{item.months===1 ? '/m' : ''}</span>
                    </div>
                    <label>Target <label className="has-text-danger">{item.target==="all" ? "~ Every person" : item.target+" people"}</label></label>
										<br />
                    <label>Get <label className="has-text-danger">{item.plays}</label> plays</label>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
      </div>
  )
}

export default Pricing
