import React from 'react'

class Stats4 extends React.Component {
	render() {
		return (
			<div className="m-container stat-bg-2">
				<div className="columns reverse-columns is-vcentered">
					<div className="column is-6-desktop is-6-tablet is-12-mobile">
						<img src="/images/stat4.png" alt="plays-graph" />
					</div>
					<div className="column has-text-right-desktop has-text-right-tablet is-offset-1-desktop is-offset-1-tablet is-5-desktop is-5-tablet is-12-mobile">
						<div className="is-size-2-desktop is-size-4-tablet is-size-5-mobile" style={{ padding: '12px 0' }}>
                        10x more powerful than Beatstars Promote							
                        </div>
						<div className="is-size-4-desktop is-size-4-tablet is-size-6-mobile" style={{ fontWeight: 'normal', padding: '12px 0' }}>
                        With thousands of beats getting produced every single day, you need to have an edge to be discovered by more users, especially by Artists. 
                        <br />
                        Beatshine helps you promote your beats to undiscovered audience through targeted personalized messages, thus increasing your track plays, increasing your followers and drastically improve your chances of being noticed by artists. 
                        <br />
                        The more the artists discover you the more the chances of your sales are
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Stats4
