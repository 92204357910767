import React from 'react'

class Stats2 extends React.Component {
	render() {
		return (
			<div className="m-container stat-bg-2">
				<div className="columns reverse-columns is-vcentered">
					<div className="column is-6-desktop is-6-tablet is-12-mobile">
						<img src="/images/stat2.png" alt="plays-graph" />
					</div>
					<div className="column has-text-right-desktop has-text-right-tablet is-offset-1-desktop is-offset-1-tablet is-5-desktop is-5-tablet is-12-mobile">
						<div className="is-size-2-desktop is-size-4-tablet is-size-5-mobile" style={{ padding: '12px 0' }}>
							Get 60,000 track plays in a month!
							</div>
						<div className="is-size-4-desktop is-size-4-tablet is-size-6-mobile" style={{ fontWeight: 'normal', padding: '12px 0' }}>
							Beatshine boosts your track plays by using hundreds of thousands of real IPs spread across US and UK which stream your tracks thus increasing your plays
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Stats2
