import React from 'react'

class Stats1 extends React.Component {
	render() {
		return (
			<div className="m-container stat-bg-1">
				<div className="columns is-vcentered">
					<div className="column is-5-desktop is-5-tablet is-12-mobile">
						<div className="is-size-2-desktop is-size-4-tablet is-size-5-mobile" style={{ padding: '12px 0' }}>
							Get 10x more followers everyday!
							</div>
						<div className="is-size-4-desktop is-size-4-tablet is-size-6-mobile" style={{ fontWeight: 'normal', padding: '12px 0' }}>
							Beatshine engages with thousands of users automatically on your behalf everyday and turn them into your followers. It automates:
							<ul
								className="is-size-5-desktop is-size-5-tablet is-size-6-mobile"
								style={{marginLeft: 24, listStyle: 'disc'}}>
								<li>Follow users</li>
								<li>Send Personalised messages</li>
								<li>Send Personalized comments on their tracks</li>
								<li>Like their tracks</li>
							</ul>
						</div>
					</div>
					<div className="column has-text-right is-offset-1-desktop is-offset-1-tablet is-6-desktop is-6-tablet is-12-mobile">
						<img src="/images/stat1.png" alt="plays-graph" />
					</div>
				</div>
			</div>
		)
	}
}

export default Stats1
