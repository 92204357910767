import React from 'react'
import Stats1 from './Stats1'
import Stats2 from './Stats2'
import Stats3 from './Stats3'
import Stats4 from './Stats4'

const Stats = () => {
    return(
        <>
            <Stats1 />
            <Stats2 />
            <Stats3 />
            <Stats4 />
        </>
    )
}

export default Stats