import React from 'react'

class Stats3 extends React.Component {
	render() {
		return (
			<div className="m-container stat-bg-1">
				<div className="columns is-vcentered">
					<div className="column is-5-desktop is-5-tablet is-12-mobile">
						<div className="is-size-2-desktop is-size-4-tablet is-size-5-mobile" style={{ padding: '12px 0' }}>
                        Sell More by Collaborating with Producers!
							</div>
						<div className="is-size-4-desktop is-size-4-tablet is-size-6-mobile" style={{ fontWeight: 'normal', padding: '12px 0' }}>
                        Beatshine enables you to collaborate with producers by sending them automated personalized messages on your behalf. While you spend your productive hours creating beats, Beatshine connects you with 1000+ targeted producers on Autopilot everyday
						</div>
					</div>
					<div className="column has-text-right is-offset-1-desktop is-offset-1-tablet is-6-desktop is-6-tablet is-12-mobile">
						<img src="/images/stat3.png" alt="plays-graph" />
					</div>
				</div>
			</div>
		)
	}
}

export default Stats3
