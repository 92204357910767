import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Front from "../components/Index/Front"
// import Features from "../components/Index/Features"
// import Install from "../components/Index/Install"
import HowToUse from "../components/Index/HowToUse"
import VideoTutorial from "../components/Index/VideoTutorial"
import Testimonial from "../components/Index/Testimonial"
import Pricing from "../components/Index/Pricing"
import FAQ from "../components/Index/FAQ"
// import HowToInstall from "../components/Index/HowToInstall"
import ReactNotification from 'react-notifications-component'
import Drift from 'react-driftjs'
import useSiteMetadata from "../components/SiteMetaData"
import Modal from "../components/Modal"
import Stats from "../components/Index/Stats"

const IndexPage = () => {
  const {driftApp} = useSiteMetadata()
  return(
    <Layout>
      <ReactNotification />
      {/*<Modal />*/}
      <SEO/>
      <Front />
      {/* <Features /> */}
      <Stats />
      <HowToUse />
      {/* <Testimonial /> */}
      <FAQ />
      <Pricing />
      {/*<VideoTutorial />*/}
    </Layout>
  )
}

export default IndexPage
